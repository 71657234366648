import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoadingPanelService } from '../../services';

@Component({
  selector: 'nexweb-loading-panel',
  templateUrl: './loading-panel.component.html',
})
export class LoadingPanelComponent implements OnInit, OnDestroy {
  public modal!: boolean;
  private _showModalSubscription$!: Subscription;
  private _hideModalSubscription$!: Subscription;

  constructor(
    private service: LoadingPanelService,
    private router: Router,
  ) {
    this._hideModalSubscription$ = this.router.events.subscribe(() => {
      this.service.modal(false);
    });
  }

  ngOnInit() {
    //modal
    this._showModalSubscription$ = this.service
      .getModal$()
      .pipe(
        map((modal: boolean) => {
          this.modal = modal;
        }),
      )
      .subscribe();
  }

  public getLoading$() {
    return this.service.getLoading$();
  }

  public getModal$() {
    return this.service.getModal$;
  }

  public loadingMessage$(): Observable<string> {
    return this.service.loadingMessage$();
  }

  ngOnDestroy(): void {
    if (this._showModalSubscription$) {
      this._showModalSubscription$.unsubscribe();
    }

    if (this._hideModalSubscription$) {
      this._hideModalSubscription$.unsubscribe();
    }
  }
}
