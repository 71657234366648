import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingPanelService {
  private _showSource$ = new Observable<boolean>();
  private _loadingMessage$ = new Observable<string>();
  private _showModalSource$ = new BehaviorSubject<boolean>(false);
  constructor() {}

  loading(value: boolean) {
    this._showSource$ = of(value);
  }

  modal(value: boolean) {
    this._showModalSource$.next(value);
  }

  show(message?: string, hideAfter?: number) {
    if (message) {
      this.message(message);
    }

    this.loading(true);

    if (hideAfter) {
      setTimeout(() => {
        this.hide(true);
      }, hideAfter);
    }
  }

  hide(clearMessage?: boolean) {
    if (clearMessage) {
      this.message('');
    }

    this.loading(false);
  }

  message(value: string) {
    this._loadingMessage$ = of(value);
  }

  getLoading$(): Observable<boolean> {
    return this._showSource$;
  }

  loadingMessage$(): Observable<string> {
    return this._loadingMessage$;
  }

  getModal$(): Observable<boolean> {
    return this._showModalSource$.asObservable();
  }
}
